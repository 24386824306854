import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { usedFunction, unusedFunction } from './utils';
import WebRoutes from './Routes/WebRoutes';
import AOS from 'aos';
import 'aos/dist/aos.css';
function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1000ms
  }, []);
  usedFunction();
  return (
    <div className="App">
      <Router>
        <Suspense
          fallback={
            <div className="flex flex-col justify-center items-center h-screen">
              <span className='text-xl font-bold text-vivamagenta-accent my-2'>Loading...</span>
            </div>
          }
        >
          <WebRoutes/>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
