import React, { useState, useEffect } from 'react';
import Logo from "../assets/Logo.png";
import LgLogo from "../assets/images/LogoIpL.png";
import { IoCartOutline } from "react-icons/io5";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Remove the curly braces
import { Cookies } from "react-cookie";
import { useCart } from "../ExcessCode/CartContext";
import axios from 'axios';
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isLoginPage  = location.pathname === '/login';
  const isRigister = location.pathname === '/register';
  const isFeedback = location.pathname === '/feedback';
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { totalProducts } = useCart();

  const cookies = new Cookies();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    navigate(`/search/${searchText}`);
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    if (token) {
      try {
        jwtDecode(token);
        setIsLoggedIn(true);
      } catch (error) {
        console.error('Invalid token');
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  //handle logout 
  const handleLogout = async () => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      // Call your logout API passing the authorization header with the token
      await axios.get(
        'https://api.agrifert.in/api/logout',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Remove the 'token' cookie only after a successful response
      await cookies.remove('token', { path: '/' });
      closeMenu()
      // Redirect to '/login'
      navigate('/login');
      window.location.reload();
     
    } catch (error) {
      cookies.remove('token', { path: '/' });
      console.error('Error during logout:', error);
      closeMenu()
      navigate("/")
     
     
      // Handle the error or redirect if necessary
    }
  };

  return (
    <nav
    className={`text-gray-900 fixed w-full z-20 top-0 start-0 transition-colors duration-300 ${
      isScrolled || !isHomePage && !isLoginPage && !isRigister && !isFeedback ? 'bg-white shadow-md' : 'bg-transparent'
    }`}
    style={{
      backdropFilter: isScrolled || !isHomePage && !isLoginPage && !isRigister  && !isFeedback ? 'none' : 'blur(7px)',
      WebkitBackdropFilter: isScrolled || !isHomePage && !isLoginPage && !isRigister && !isFeedback  ? 'none' : 'blur(7px)',
    }}
  >
  
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-1">
        <a href="https://www.agrifert.in/" className="flex items-center space-x-2 rtl:space-x-reverse">
          <img className="hidden lg:block" src={LgLogo} height="150px" width="100px" alt="Agrifert" />
          <img className="block lg:hidden" src={Logo} height="50px" width="50px" alt="Agrifert" />
        </a>
        <div className="flex md:order-3 space-x-2 md:space-x-1 items-center rtl:space-x-reverse ">
          <div className="relative ">
            <div className="absolute inset-y-0  start-0 flex items-center ps-4  pointer-events-none focus:ring-green-900 focus:border-green-900  ">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-500 focus:ring-green-900 focus:border-green-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <form onSubmit={handleSearch}>
              <input
                type="search"
                name="search"
                onChange={handleInputChange}
                value={searchText}
                id="default-search"
                className="block w-full px-1 py-1.5 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-900 focus:border-green-900 dark:border-gray-600 dark:placeholder-gray-400  "
                placeholder="Search Products"
                required
              />
            </form>
          </div>
          <div className='flex text-center items-center justify-center mt-1'>
            <NavLink exact to="/cart" >
            <div className="relative inline-flex items-center">
              <IoCartOutline className="w-10 h-10 p-2 text-sm text-gray-800 md:ms-5 " />
              <span className="absolute top-0 right-0 block w-4 h-4 text-xs font-medium leading-tight text-center text-white bg-gray-900 rounded-full"> {parseInt(totalProducts)}
    </span>
            </div>
            </NavLink>
          </div>
          <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-800 md:hidden dark:text-gray-800"
            aria-controls="navbar-sticky"
            aria-expanded={isMenuOpen}
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-2  ${isMenuOpen ? '' : 'hidden'}`} id="navbar-sticky">
          <ul className="flex flex-col p-5 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:space-x-6 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 ">
            <li>
              <NavLink exact to="/" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"} onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/about-us" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"} onClick={closeMenu}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/shop" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"} onClick={closeMenu}>
                Shop
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/news-and-events" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"} onClick={closeMenu}>
                News & Events
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/gallery" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"} onClick={closeMenu}>
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/contact-us" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"} onClick={closeMenu}>
                Contact Us
              </NavLink>
            </li>
            {!isLoggedIn ? (
              <>
                <li>
                  <NavLink exact to="/register" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"} onClick={closeMenu}>
                    Register
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/login" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"} onClick={closeMenu}>
                    Login
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li className="relative hidden lg:block">
                  <NavLink
                    id="dropdownInformationButton"
                    onClick={toggleDropdown}
                    className="flex justify-between items-center block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent"
                  >
                    Account
                    <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                  </NavLink>
                  {isDropdownOpen && (
                    <div id="dropdownInformation" className="absolute right-0 mt-2 w-44 bg-white rounded-md shadow-lg z-50 ">
                      <ul className="py-2 text-sm text-gray-900 ">
                        <li>
                          <NavLink exact to="/user/profile" className="block px-4 py-2 hover:bg-gray-100" onClick={toggleDropdown}>Profile</NavLink>
                        </li>
                        <li>
                          <NavLink exact to="/user/my-orders"  className="block px-4 py-2 hover:bg-gray-100" onClick={toggleDropdown}>My Orders</NavLink>
                        </li>
                      </ul>
                      <hr />
                      <div className="py-2">
                        <NavLink  onClick={handleLogout} className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-100">Log out</NavLink>
                      </div>
                    </div>
                  )}
                </li>
                <li>
                  <NavLink exact to="/user/profile" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent lg:hidden" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent lg:hidden"} onClick={closeMenu}>
                    Profile
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/user/my-orders" className={({ isActive }) => isActive ? "block py-2 px-3 text-green-900 font-bold  rounded hover:bg-gray-100 md:hover:bg-transparent lg:hidden" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent lg:hidden"} onClick={closeMenu}>
                    My Orders
                  </NavLink>
                </li>
                <li>
                  <NavLink  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent lg:hidden"  onClick={handleLogout}>
                    Log out
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
